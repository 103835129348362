import { FETCH_VIDEO } from "../actions/actionTypes";

export const videoReducer = (state = "loading", action) => {
  switch (action.type) {
    case FETCH_VIDEO:
      return action.payload;
    default:
      return state;
  }
};

export default videoReducer;

import { FETCH_FEATURED_CHANNELS } from "../actions/actionTypes";

export const channelReducer = (state = "loading", action) => {
    switch (action.type) {
        case FETCH_FEATURED_CHANNELS:
            return action.payload;
        default:
            return state;
    }
};

export default channelReducer;

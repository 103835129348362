import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import requireAuth from "./components/auth/requireAuth";

import { connect } from "react-redux";
import { fetchUser } from "./actions";

import "./App.scss";
const VideoDetails = React.lazy(() => import("./views/Videos/VideoDetails"));

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() =>
  import("./containers/DefaultLayout/DefaultLayout")
);

// Pages
// const Login = React.lazy(() => import('./views/Pages/Login'));
// const Register = React.lazy(() => import('./views/Pages/Register'));

class App extends Component {
  constructor(props) {
    super(props);
    this.props.fetchUser(); // check that the user is logged in.
  }

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} /> */}
            <Route
              path="/video/:id"
              name="Video Player"
              render={(props) => <VideoDetails {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default connect(null, { fetchUser })(App);

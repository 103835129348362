import { combineReducers } from "redux";

import auth from "./authReducer";
import channels from "./channelsReducer";
import channel from "./channelReducer";
import video from "./videoReducer";

export default combineReducers({
  auth,
  channels,
  channel,
  video,
});

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBP2wP0b9_VCHsoNIGhZfdkLZs9stS1ciQ",
  authDomain: "web.kumu.tv",
  databaseURL: "https://kumu-spark.firebaseio.com",
  projectId: "kumu-spark",
  storageBucket: "kumu-spark.appspot.com",
  messagingSenderId: "54575598021",
  appId: "1:54575598021:web:e6e4b9692ad49ad7",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const authRef = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();

const firestore = firebase.firestore();
const database = firebase.database();

export const videosCollection = firestore.collection("videos");
export const channelsCollection = firestore.collection("channels");

export const videosRef = database.ref("videos");
export const channelsRef = database.ref("channels");

export const spacesCollection = firestore.collection("spaces");
export const tagsCollection = firestore.collection("tags");
export const friendsCollection = firestore.collection("friends");

// USER + AUTH
export const USER_SIGNIN = "USER_SIGNIN";

// CHANNELS
export const FETCH_FEATURED_CHANNELS = "FETCH_FEATURED_CHANNELS";
export const FETCH_CHANNEL_DETAILS = "FETCH_CHANNEL_DETAILS";

// PLAYLISTS
export const FETCH_PLAYLIST = "FETCH_PLAYLIST";

// VIDEOS
export const FETCH_FEATURED_VIDEOS = "FETCH_FEATURED_VIDEOS";
export const FETCH_RECOMMENDED_VIDEOS = "FETCH_RECOMMENDED_VIDEOS";
export const FETCH_POPULAR_VIDEOS = "FETCH_POPULAR_VIDEOS";
export const FETCH_VIDEO = "FETCH_VIDEO";
export const SEARCH_VIDEOS = "SEARCH_VIDEOS";

// export const FETCH_RECOMMENDED_CHANNELS = "FETCH_RECOMMENDED_CHANNELS";
// export const FETCH_POPULAR_CHANNELS = "FETCH_POPULAR_CHANNELS";

// export const FETCH_USER_CHANNELS = "FETCH_USER_CHANNELS";
// export const FETCH_USER_VIDEOS = "FETCH_USER_VIDEOS";
// export const FETCH_USER_COLLECTIONS = "FETCH_USER_COLLECTIONS";
// export const FETCH_USER_NOTES = "FETCH_USER_COLLECTIONS";
// export const FETCH_USER_SPACES = "FETCH_USER_SPACES";

// export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
// export const FETCH_USER_PREFERENCES = "FETCH_USER_PREFERENCES";
// export const FETCH_USER_FRIENDS = "FETCH_USER_FRIENDS";
// export const FETCH_USER_NOTIFICATIONS = "FETCH_USER_NOTIFICATIONS";

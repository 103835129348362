import {
  authRef,
  channelsCollection,
  videosCollection,
  channelsRef,
  videosRef,
  // spacesCollection,
  // friendsCollection,
  // collectionsCollection,
  // sessionsCollection,
  provider,
} from "../config/firebase";

import {
  USER_SIGNIN,
  FETCH_FEATURED_CHANNELS,
  FETCH_CHANNEL_DETAILS,
  FETCH_VIDEO
} from "./actionTypes";

const data_source = "DATABASE"; // FIRESTORE || DATABASE

export const signIn = () => (dispatch) => {
  provider.addScope("profile");
  provider.addScope("email");

  // TODO: Re-enable when the Consent Screen is verified
  // provider.addScope('https://www.googleapis.com/auth/youtube');
  // provider.addScope('https://www.googleapis.com/auth/youtube.readonly');

  authRef
    .signInWithPopup(provider)
    .then((result) => { })
    .catch((error) => {
      console.log(error);
    });
};

export const signOut = () => (dispatch) => {
  authRef
    .signOut()
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchUser = () => (dispatch) => {
  authRef.onAuthStateChanged((user) => {
    //console.log("onAuthStateChanged: ", user);
    if (user) {
      dispatch({
        type: USER_SIGNIN,
        payload: user,
      });
    } else {
      dispatch({
        type: USER_SIGNIN,
        payload: null,
      });
    }
  });
};

export const fetchFeaturedChannels = () => async (dispatch) => {
  // TODO: TEST!!! - PROBABLY DOESN'T WORK
  if (data_source === "FIRESTORE") {
    channelsCollection.get()
      .then(function (doc) {
        if (doc.exists) {

          let obj = doc.data();
          let result = Object.keys(obj).map(function (key) {
            return Object.assign(obj[key], { "id": String(key) });
          });

          dispatch({
            type: FETCH_FEATURED_CHANNELS,
            payload: result,
          });
        } else {
          dispatch({
            type: FETCH_FEATURED_CHANNELS,
            payload: null,
          });
        }
      })
      .catch(function (error) {
        console.log("Error getting featured channels:", error);
      });
  }
  else {
    channelsRef
      .once('value')
      .then(function (snapshot) {

        if (snapshot.val()) {

          let obj = snapshot.val();

          let result = Object.keys(obj).map(function (key) {
            return Object.assign(obj[key], { "id": String(key) });
          });

          dispatch({
            type: FETCH_FEATURED_CHANNELS,
            payload: result,
          });
        } else {
          dispatch({
            type: FETCH_FEATURED_CHANNELS,
            payload: null,
          });
        }
      })
      .catch(function (error) {
        console.log("Error getting featured channels:", error);
      });;
  }
};

export const clearFeaturedChannels = () => async (dispatch) => {
  dispatch({
    type: FETCH_FEATURED_CHANNELS,
    payload: null,
  });
};

export const fetchChannel = (channel_id) => async (dispatch) => {

  if (data_source === "FIRESTORE") {
    channelsCollection.doc(channel_id).get()
      .then(function (doc) {
        if (doc.exists) {
          dispatch({
            type: FETCH_CHANNEL_DETAILS,
            payload: doc.data(),
          });
        } else {
          dispatch({
            type: FETCH_CHANNEL_DETAILS,
            payload: null,
          });
        }
      })
      .catch(function (error) {
        console.log("Error getting channel:", error);
      });
  }
  else {
    channelsRef.child(channel_id)
      .once('value')
      .then(function (snapshot) {
        if (snapshot.val()) {
          dispatch({
            type: FETCH_CHANNEL_DETAILS,
            payload: snapshot.val(),
          });
        } else {
          dispatch({
            type: FETCH_CHANNEL_DETAILS,
            payload: null,
          });
        }
      })
      .catch(function (error) {
        console.log("Error getting channel:", error);
      });;
  }
};

export const clearChannel = () => async (dispatch) => {
  dispatch({
    type: FETCH_CHANNEL_DETAILS,
    payload: null,
  });
};

export const fetchVideo = (video_id) => async (dispatch) => {
  if (data_source === "FIRESTORE") {
    videosCollection.doc(video_id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          dispatch({
            type: FETCH_VIDEO,
            payload: doc.data(),
          });
        } else {
          dispatch({
            type: FETCH_VIDEO,
            payload: null,
          });
        }
      })
      .catch(function (error) {
        console.log("Error getting video:", error);
      });
  }
  else {
    videosRef.child(video_id)
      .once('value')
      .then(function (snapshot) {
        if (snapshot.val()) {
          dispatch({
            type: FETCH_VIDEO,
            payload: snapshot.val(),
          });
        } else {
          dispatch({
            type: FETCH_VIDEO,
            payload: null,
          });
        }
      })
      .catch(function (error) {
        console.log("Error getting channel:", error);
      });;
  }
};

export const clearVideo = () => async (dispatch) => {
  dispatch({
    type: FETCH_VIDEO,
    payload: null,
  });
};
